import React,{Component} from 'react'
import {Link} from 'react-router-dom'

export default class Header_en extends Component{

    render () {
        return (
            <div>
                <header id="header">
                    <Link className="logo" to={"/main_en"}><span>Your trusted legal advisor</span></Link>
                    {/*<div className="logo"><a href="home.html"><span>Your trusted legal advisor</span></a></div>*/}
                    <Link to={"/main_en"}>English</Link>
                    <Link to={"/main_fr"}>Français</Link>
                    <Link to={"/main_cn"}>中文</Link>
                </header>
            </div>
        )
    }
}