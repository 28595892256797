import React,{Component} from 'react'
import {Link} from 'react-router-dom'

export default class Header_cn extends Component{

    render () {
        return (
            <div>
                <header id="header">
                    <Link className="logo" to={"/main_cn"}><span>专业品质 值得信赖</span></Link>
                    <Link to={"/main_en"}>English</Link>
                    <Link to={"/main_fr"}>Français</Link>
                    <Link to={"/main_cn"}>中文</Link>
                </header>
            </div>
        )
    }
}