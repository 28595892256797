import React,{Component} from 'react'
import Header_fr from "./header_fr";
export default class About_fr extends Component{

    render () {
        return (
            <div id="main">
                <Header_fr/>
                <section className="wrapper">
                    <div className="inner">
                        <header className="align-center">
                            <h1>JIE HAN | AVOCATE</h1>
                            <p>Au plaisir de contribuer à votre succès!</p>
                        </header>
                        <div className="flex flex-1">
                            <div className="col">
                                <p>Détentrice d'un baccalauréat et d’une maîtrise en droit au Canada
                                    (Université de Montréal) et membre du Barreau de Québec, Me Han a
                                    préalablement complété un baccalauréat en droit en Chine. </p>
                                <p>Au cours de ses études au Canada, elle a cumulé de l’expérience pratique
                                    importante auprès de cabinets boutiques, notamment au niveau de demandes
                                    d’enregistrement et de la gestion de marque de commerces et de la rédaction
                                    de diverses ententes commerciales. Plus tard, Me Han a complété son stage du
                                    Barreau dans un cabinet boutique en droit de l’immigration. </p>
                                <p>Depuis le démarrage de son propre cabinet, elle dévoue son temps à offrir des
                                    solutions juridiques pratiques et adaptés aux entrepreneurs québécois et internationaux.</p>
                                <p>Dans le cadre de sa pratique, Me Han consacre de deux à dix heures par semaine en
                                    moyenne afin de conseiller des clients sur une base<em> pro bono</em>.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <footer id="footer">
                    <div className="copyright">
                        <p>Copyright &copy; 2020 <a>Jie Han Consulting</a>. All rights reserved.</p>
                    </div>
                </footer>
            </div>
        )
    }
}