import React,{Component} from 'react'
import {Link} from "react-router-dom";
import Header_en from "./header_en";
export default class MainEn extends Component{

    componentDidMount() {
            const days = 'sunday,monday,tuesday,wednesday,thursday,friday,saturday'.split(',');
            document.getElementById(days[(new Date()).getDay()]).className = 'bold';
    }

    render () {
        return (
            <div>
                <Header_en/>
                <section id="banner">
                    <div className="inner">
                        <header>
                            <div className="inline">
                                <img src="images/logo_jiehan.jpg"/>
                                <h1>Jie Han Consulting</h1>
                            </div>
                            <p><em>Tough times never last, tough people do.</em></p>
                        </header>
                        <a href="#main" className="button big scrolly">Learn More</a>
                    </div>
                </section>

                <div id="main">
                    <section className="wrapper style1">
                        <div className="inner">
                            <div className="flex flex-2">
                                <div className="col col1">
                                    <div className="image round fit">
                                        <Link to={"/about_en"} className="link"><img src="images/portrait.jpg"
                                                                                       alt=""/></Link>

                                    </div>
                                </div>
                                <div className="col col2">
                                    <h3>JIE HAN | LAWYER</h3>
                                    <p>
                                        Being a lawyer in Quebec and a jurist in China, I advise local and international<br/>
                                        clients with their business law, immigration law and family law matters.
                                    </p>
                                    <Link to={"/about_en"} className="button">Learn More</Link>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="wrapper style">
                        <div className="inner">
                            <div className="flex flex-2">
                                <div className="col col2">
                                    <h3>SERVICES</h3>
                                    <p>We offer services in the following areas of practice:
                                        <ul>
                                            <li>Business Law</li>
                                            <li>Trademarks</li>
                                            <li>Immigration Law</li>
                                            <li>Family Law</li>
                                            <li>Legal Translation</li>
                                        </ul>
                                    </p>
                                    <Link to={"/service_en"} className="button">Learn More</Link>

                                </div>
                                <div className="col col1 first">
                                    <div className="image round fit">
                                        <Link to={"/service_en"} className="link"><img src="images/law2.jpg" alt=""/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="wrapper style1">
                        <div className="inner">
                            <div className="flex flex-2">
                                <div className="col col1">
                                    <div className="image round fit">
                                        <Link to={"/inquiry_en"}className="link"><img src="images/questionMark.jpg"
                                                                                        alt=""/></Link>
                                    </div>
                                </div>
                                <div className="col col2">
                                    <h3>INQUIRY | CONSULTATION</h3>
                                    <p>Have a question? Do not hesitate to contact us, we will repond quickly. </p>
                                    <Link to={"/inquiry_en"}className="button">Learn More</Link>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="wrapper style2">
                        <div className="inner">
                            <header className="align-center">
                                <h2>CONTACT INFORMATION | OFFICE HOURS</h2>
                                <p>We love our clients, so feel free to call or email during business hours.</p>
                            </header>
                            <div className="flex flex-2">
                                <div className="col align-center">
                                    <table>
                                        <tr className="bold">
                                            <td colSpan="2">CONTACT</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">TELEPHONE</td>
                                            <td>(514) 660 - 6611</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">E-MAIL</td>
                                            <td>han.qc@outlook.com</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">WECHAT ID</td>
                                            <td>QuebecLawyer</td>
                                        </tr>
                                    </table>
                                    <div >
                                        <img src="images/wechat.jpg" style={{float:"center"}}
                                             height="130" width="130" alt=""/>
                                        <p>Scan the QR code for wechat users. </p>
                                    </div>

                                </div>
                                <div className="col align-center">
                                    <table>
                                        <tr className="bold">
                                            <td colSpan="2">HOURS</td>
                                        </tr>
                                        <tr id="monday">
                                            <td>MON</td>
                                            <td>9:00 - 17:00</td>
                                        </tr>
                                        <tr id="tuesday">
                                            <td>TUE</td>
                                            <td>9:00 - 17:00</td>
                                        </tr>
                                        <tr id="wednesday">
                                            <td>WED</td>
                                            <td>9:00 - 17:00</td>
                                        </tr>
                                        <tr id="thursday">
                                            <td>THU</td>
                                            <td>9:00 - 17:00</td>
                                        </tr>
                                        <tr id="friday">
                                            <td>FRI</td>
                                            <td>9:00 - 17:00</td>
                                        </tr>
                                        <tr id="saturday">
                                            <td>SAT</td>
                                            <td>closed</td>
                                        </tr>
                                        <tr id="sunday">
                                            <td>SUN</td>
                                            <td>closed</td>
                                        </tr>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </section>

                </div>

                <footer id="footer">
                    <div className="copyright">
                        <p>Copyright &copy; 2021 <a>Jie Han Consulting</a>. All rights reserved.
                        </p>
                    </div>
                </footer>

            </div>

        )
    }
}