import React,{Component} from 'react'
import Header_en from "./header_en";
export default class About_en extends Component{

    render () {
        return (
                <div id="main">
                    <Header_en/>
                    <section className="wrapper">
                        <div className="inner">
                            <header className="align-center">
                                <h1>JIE HAN | LAWYER</h1>
                                <p>Looking forward to contributing to your success</p>
                            </header>
                            <div className="flex flex-1">
                                <div className="col">
                                    <p>Holding both a Bachelor and a Master degree in Law in Canada (University of
                                        Montreal)
                                        and being a member of the Quebec Bar, Me Han previously completed a Bachelor
                                        degree
                                        in Law in China.</p>
                                    <p>During her studies in Canada, she gained significant practical experience in
                                        boutique
                                        law firms, particularly with respect to trademark​​ registration and management
                                        as well as the drafting of various commercial agreements. Afterwards, Me Han
                                        completed
                                        her articling for the Bar at an immigration law firm. </p>
                                    <p>Since starting her own firm, she devotes her time to providing practical and
                                        tailored legal solutions to the Quebec and international entrepreneurs.</p>
                                    <p>As part of her practice, Me Han devotes two to ten hours per week on average to
                                        advise certain clients on a <em>pro bono</em> basis.</p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <footer id="footer">
                        <div className="copyright">
                            <p>Copyright &copy; 2020 <a>Jie Han Consulting</a>. All rights reserved.</p>
                        </div>
                    </footer>
                </div>
        )
    }
}