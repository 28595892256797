import React,{Component} from 'react'
import emailjs from 'emailjs-com'
import Header_cn from "./header_cn";

export default class Inquiry_cn extends Component{

    sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('gmail','template_1853f1d',e.target,'user_4x8JFBx5bpqK0p8aB3LKG').
        then(result => {
            console.log(result.text)
        }).catch(
            error =>{console.log(error.text)}
        )
        e.target.reset()
    }

    render () {
        return (
            <div>
                <Header_cn/>
                <br/>
                <br/>

                <div id="form">

                    <h3>咨询服务</h3>

                    <p>如有法律问题请给我们留言，我们会尽快回复您。</p>

                    <hr />

                    <form onSubmit={this.sendEmail} enctype="multipart/form-data">
                        <div class="row uniform">
                            <div class="6u 12u$(xsmall)">
                                <input type="text" name="name" id="name" placeholder="名字" />
                            </div>
                            <div class="6u$ 12u$(xsmall)">
                                <input type="email" name="email" id="email" placeholder="邮箱" />
                            </div>
                            <div class="12u$">
                                <div class="select-wrapper">
                                    <select name="category" id="category">
                                        <option value="Unspecified">- 分类 (有选项) -</option>
                                        <option value="Business Law">商业资讯</option>
                                        <option value="Trademarks">商标咨询</option>
                                        <option value="Immigration Law">移民咨询</option>
                                        <option value="Family Law">家庭事务咨询</option>
                                        <option value="Legal Translation">文件翻译</option>
                                    </select>
                                </div>
                            </div>

                            <div class="4u$ 12u$(small)">
                                <input type="checkbox" id="priority-high" name="priority" value="high"/>
                                <label for="priority-high">紧急</label>
                            </div>

                            <div class="12u$">
                                <textarea name="message" id="message" placeholder="请在此输入信息..." rows="6"></textarea>
                            </div>

                            <div class="12u$">
                                <ul class="actions">
                                    <li><input type="submit" value="发送消息" /></li>
                                </ul>
                            </div>
                        </div>
                    </form>

                    <hr />
                </div>

                <footer id="footer">
                    <div class="copyright">
                        <p>Copyright &copy; 2021 <a>Jie Han Consulting</a>. All rights reserved.</p>
                    </div>
                </footer>

            </div>
        )
    }
}