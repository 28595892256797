import React,{Component} from 'react'
import {Link} from "react-router-dom";
import Header_cn from "./header_cn";

export default class MainCn extends Component{
    componentDidMount() {
        const days = 'sunday,monday,tuesday,wednesday,thursday,friday,saturday'.split(',');
        document.getElementById(days[(new Date()).getDay()]).className = 'bold';
    }

    render () {
        return (
            <div>
                <Header_cn/>
                <section id="banner">
                    <div className="inner">
                        <header>
                            <div className="inline">
                                <img src="images/logo_jiehan.jpg"/>
                                <h1>韩洁同名律师事务所</h1>
                            </div>
                            <p><em>困境从不长久，奋斗自始至终。</em></p>
                        </header>
                        <a href="#main" className="button big scrolly">更多内容</a>
                    </div>
                </section>


                <div id="main">

                    <section className="wrapper style1">
                        <div className="inner">
                            <div className="flex flex-2">
                                <div className="col col1">
                                    <div className="image round fit">
                                        <Link to={"/about_cn"} className="link"><img src="images/portrait.jpg"
                                                                                       alt=""/></Link>
                                    </div>
                                </div>
                                <div className="col col2">
                                    <h3>韩洁 | 律师</h3>
                                    <p>作为魁北克注册律师，中加两地法律学者，本人为国内外客户提供公司法，移民法及婚姻法的相关服务。<br/>
                                    创业后一直致力于为同胞提供优质的司法服务。</p>
                                    <Link to={"/about_cn"} className="button">了解更多</Link>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section className="wrapper style">
                        <div className="inner">
                            <div className="flex flex-2">
                                <div className="col col2">
                                    <h3>服务内容</h3>
                                    <p>我们为您提供如下相关法律的专业服务业务:
                                        <ul>
                                            <li>公司法</li>
                                            <li>商标法</li>
                                            <li>移民法</li>
                                            <li>婚姻家庭法</li>
                                            <li>法律文书翻译</li>
                                        </ul>
                                    </p>
                                    <Link to={"/service_cn"} className="button">更多内容</Link>
                                </div>
                                <div className="col col1 first">
                                    <div className="image round fit">
                                        <Link to={"/service_cn"} className="link"><img src="images/law2.jpg" alt=""/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section className="wrapper style1">
                        <div className="inner">
                            <div className="flex flex-2">
                                <div className="col col1">
                                    <div className="image round fit">
                                        <Link to={"/inquiry_cn"}className="link"><img src="images/questionMark.jpg"
                                                                                        alt=""/></Link>
                                    </div>
                                </div>
                                <div className="col col2">
                                    <h3>咨询服务</h3>
                                    <p>如有需求请及时联系我们，我们会尽快对您的问题进行回馈。</p>
                                    <Link to={"/inquiry_cn"}className="button">更多内容</Link>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="wrapper style2">
                        <div className="inner">
                            <header className="align-center">
                                <h2>联系方式 | 工作时间</h2>
                                <p>亲爱的客户，请随时与我们保持联系</p>
                            </header>
                            <div className="flex flex-2">
                                <div className="col align-center">
                                    <table>
                                        <tr className="bold">
                                            <td colSpan="2">联系方式</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">电话号码</td>
                                            <td>(514) 660 - 6611</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">电子邮箱</td>
                                            <td>han.qc@outlook.com</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">微信账号</td>
                                            <td>QuebecLawyer</td>
                                        </tr>
                                    </table>
                                    <div >
                                        <img src="images/wechat.jpg" height="125" width="125" style={{float:"center"}} alt=""/>
                                    </div>
                                    <p>请扫描此二维码添加微信</p>
                                </div>
                                <div className="col align-center">
                                    <table>
                                        <tr className="bold">
                                            <td colSpan="2">工作时间</td>
                                        </tr>
                                        <tr id="monday">
                                            <td>星期一</td>
                                            <td>9:00 - 17:00</td>
                                        </tr>
                                        <tr id="tuesday">
                                            <td>星期二</td>
                                            <td>9:00 - 17:00</td>
                                        </tr>
                                        <tr id="wednesday">
                                            <td>星期三</td>
                                            <td>9:00 - 17:00</td>
                                        </tr>
                                        <tr id="thursday">
                                            <td>星期四</td>
                                            <td>9:00 - 17:00</td>
                                        </tr>
                                        <tr id="friday">
                                            <td>星期五</td>
                                            <td>9:00 - 17:00</td>
                                        </tr>
                                        <tr id="saturday">
                                            <td>星期六</td>
                                            <td>休息</td>
                                        </tr>
                                        <tr id="sunday">
                                            <td>星期天</td>
                                            <td>休息</td>
                                        </tr>
                                    </table>
                                </div>


                            </div>
                        </div>
                    </section>

                </div>

                <footer id="footer">
                    <div className="copyright">
                        <p>Copyright &copy; 2021 <a>Jie Han Consulting</a>. All rights reserved.
                        </p>
                    </div>
                </footer>
            </div>
        )
    }
}