import React,{Component} from 'react'
import Header_fr from "./header_fr";

export default class Service_fr extends Component{

    render () {
        return (
            <div>
                <Header_fr/>
                <section className="wrapper">
                    <div className="inner">
                        <header className="align-center">
                            <h1>SERVICES | DOMAINES DE PRATIQUE</h1>
                            <p>Au plaisir de contribuer à votre succès !</p>
                        </header>
                        <div className="flex flex-2">
                            <div className="col col2">
                                <ul className="noBulletPoint">
                                    <li>
                                        <strong>Droit des affaires</strong>
                                        <ul>
                                            <li>Choix de la structure juridique d’une entreprise</li>
                                            <li>Recherche et réservation de nom</li>
                                            <li>Incorporation de sociétés par actions et rédaction de conventions de sociétés</li>
                                            <li>Rédaction de conventions unanimes entre actionnaires</li>
                                            <li>Rédaction et négociation de conventions d’achat d’actions ou d’actifs</li>
                                            <li>Tenue de livres de procès-verbaux, agir à titre de secrétaire corporative de sociétés privées</li>
                                            <li>Rédaction et négociation de conventions commerciales de toute sorte</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Marques de commerce</strong>
                                        <ul>
                                            <li>Vérification de la disponibilité d’une marque de commerce</li>
                                            <li>Préparation et la production d’une demande d’enregistrement d’une marque de commerce<br/>
                                                devant l’Office de la propriété intellectuelle du Canada</li>
                                            <li>Poursuite de la demande d’enregistrement jusqu’à l’obtention du certificat d’enregistrement </li>
                                            <li>Renouvellement d’une marque de commerce</li>
                                            <li>Transfert de marque de commerce</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Droit de l’immigration</strong>
                                        <ul>
                                            <li>Visa de visiteur</li>
                                            <li>Permis d’étude</li>
                                            <li>Permis de travail</li>
                                            <li>Étude d’impact sur le marché du travail</li>
                                            <li>Certificat d’acceptation du Québec (CAQ)</li>
                                            <li>Résidence permanente</li>
                                            <li>Citoyenneté</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Droit de la famille</strong>
                                        <ul>
                                            <li>Divorce</li>
                                            <li>Garde d’enfants et droits de visite</li>
                                            <li>Droit familial international</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Traduction juridique</strong>
                                        <ul>
                                            <li>Traduction de contrats et de procédures en français, en anglais et en mandarin</li>
                                        </ul>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <footer id="footer">
                    <div className="copyright">
                        <p>Copyright &copy; 2020 <a>Jie Han Consulting</a>. All rights reserved.</p>
                    </div>
                </footer>

            </div>
        )
    }
}