import React,{Component} from 'react'
import Header_en from "./header_en";

export default class Service_en extends Component{

    render () {
        return (
            <div>
                <Header_en/>
                <section className="wrapper">
                    <div className="inner">
                        <header className="align-center">
                            <h1>SERVICES | AREAS OF PRACTICE</h1>
                            <p>Looking forward to contributing to your success!</p>
                        </header>
                        <div className="flex flex-2">
                            <div className="col col2">
                                <ul className="noBulletPoint">
                                    <li>
                                        <strong>Business Law</strong>
                                        <ul>
                                            <li>Choice of the legal structure for a business</li>
                                            <li>Name research and reservation</li>
                                            <li>Incorporation or corporations and drafting of partnership agreements
                                            </li>
                                            <li>Drafting of unanimous shareholders’ agreements</li>
                                            <li>Drafting and negotiation of share or asset purchase agreements</li>
                                            <li>Maintaining minute book and acting as corporate secretary for private
                                                companies
                                            </li>
                                            <li>Drafting and negotiation of various commercial contracts</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Trademarks</strong>
                                        <ul>
                                            <li>Checking the availability of a trademark</li>
                                            <li>Preparation and filing of an application for the registration of a
                                                trademark before the Canadian Intellectual Property Office
                                            </li>
                                            <li>Continuation of the registration request until the registration
                                                certificate is obtained
                                            </li>
                                            <li>Renewal of a trademark</li>
                                            <li>Trademark transfer</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Immigration Law</strong>
                                        <ul>
                                            <li>Visitor visa</li>
                                            <li>Study permit</li>
                                            <li>Work permit</li>
                                            <li>Study of the impact on the labor market</li>
                                            <li>Quebec Acceptance Certificate (CAQ)</li>
                                            <li>Permanent residence</li>
                                            <li>Citizenship</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Family Law</strong>
                                        <ul>
                                            <li>Divorce</li>
                                            <li>Child custody and visiting rights</li>
                                            <li>International family law</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>Legal Translation</strong>
                                        <ul>
                                            <li>Translation of contracts and proceedings in French, English and
                                                Mandarin
                                            </li>
                                        </ul>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <footer id="footer">
                    <div className="copyright">
                        {/*<ul className="icons">*/}
                        {/*    <li><a href="#" className="icon fa-twitter"><span className="label">Twitter</span></a></li>*/}
                        {/*    <li><a href="#" className="icon fa-facebook"><span className="label">Facebook</span></a>*/}
                        {/*    </li>*/}
                        {/*    <li><a href="#" className="icon fa-instagram"><span className="label">Instagram</span></a>*/}
                        {/*    </li>*/}
                        {/*    <li><a href="#" className="icon fa-snapchat"><span className="label">Snapchat</span></a>*/}
                        {/*    </li>*/}
                        {/*</ul>*/}
                        <p>Copyright &copy; 2020 <a>Jie Han Consulting</a>. All rights reserved.</p>
                    </div>
                </footer>

            </div>
        )
    }
}