import React,{Component} from 'react'

import {Link,Route,Switch,Redirect} from "react-router-dom";

import MainEn from "./index_en"
import MainCn from "./index_cn"
import MainFr from "./index_fr"
import Inquiry_en from "./Inquiry_en";
import Inquiry_fr from "./inquiry_fr";
import Inquiry_cn from "./inquiry_cn";
import About_en from "./about_en";
import About_fr from "./about_fr";
import About_cn from "./about_cn";
import Service_en from "./service_en";
import Service_fr from "./service_fr";
import Service_cn from "./service_cn";

export default class App extends Component{

    render () {
        return(
            <div>
                <div>
                    <Switch>
                        <Route path="/main_en" component={MainEn}/>
                        <Route path="/main_fr" component={MainFr}/>
                        <Route path="/main_cn" component={MainCn}/>
                        <Route path="/inquiry_en" component={Inquiry_en}/>
                        <Route path="/inquiry_fr" component={Inquiry_fr}/>
                        <Route path="/inquiry_cn" component={Inquiry_cn}/>
                        <Route path="/about_en" component={About_en}/>
                        <Route path="/about_fr" component={About_fr}/>
                        <Route path="/about_cn" component={About_cn}/>
                        <Route path="/service_en" component={Service_en}/>
                        <Route path="/service_fr" component={Service_fr}/>
                        <Route path="/service_cn" component={Service_cn}/>
                        {<Redirect to="/main_fr"/>}
                    </Switch>
                </div>

            </div>
        )

    }
}
