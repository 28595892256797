import React,{Component} from 'react'
import Header_cn from "./header_cn";

export default class Service_cn extends Component{

    render () {
        return (
            <div>
                <Header_cn/>
                <section className="wrapper">
                    <div className="inner">
                        <header className="align-center">
                            <h1>服务项目 | 涉及领域</h1>
                            <p>非常期待有机会为您的成功助力!</p>
                        </header>
                        <div className="flex flex-2">
                            <div className="col col2">
                                <ul className="noBulletPoint">
                                    <li>
                                        <strong>公司法</strong>
                                        <ul>
                                            <li>公司类型及企业架构的选择</li>
                                            <li>公司名称的检索与保留</li>
                                            <li>公司的建立</li>
                                            <li>合伙人协议的撰写</li>
                                            <li>股东协议的撰写</li>
                                            <li>股份买卖及资产买卖协议的起草与协商</li>
                                            <li>公司章程及细则的撰写与保存</li>
                                            <li>其他各类商业合同的起草与谈判，包括与客户，供应商，出租方的相关合约</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>商标法</strong>
                                        <ul>
                                            <li>商标检索</li>
                                            <li>商标注册的准备及申请书的撰写与提交</li>
                                            <li>商标申请的追踪及与加拿大知识产权局的沟通</li>
                                            <li>对相同及相似商标的异议</li>
                                            <li>更新注册商标</li>
                                            <li>注册商标转让</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>移民法</strong>
                                        <ul>
                                            <li>旅游签证</li>
                                            <li>学生签证</li>
                                            <li>工作签证</li>
                                            <li>劳动力市场影响分析</li>
                                            <li>魁省接收函</li>
                                            <li>永居居民申请</li>
                                            <li>公民申请</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>家庭法</strong>
                                        <ul>
                                            <li>离婚</li>
                                            <li>子女抚养权</li>
                                            <li>国际家庭法律纠纷</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>证件翻译</strong>
                                        <ul>
                                            <li>司法文书的中英法三语翻译</li>
                                        </ul>
                                    </li>

                                </ul>
                            </div>

                        </div>
                    </div>
                </section>

                <footer id="footer">
                    <div className="copyright">
                        <p>Copyright &copy; 2020 <a>Jie Han Consulting</a>. All rights reserved.</p>
                    </div>
                </footer>
            </div>
        )
    }
}