import React,{Component} from 'react'
import emailjs from 'emailjs-com'
import Header_fr from "./header_fr";

export default class Inquiry_fr extends Component{

    sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('gmail','template_1853f1d',e.target,'user_4x8JFBx5bpqK0p8aB3LKG')
            .then(result => {
            console.log(result.text)
        }).catch(
            error =>{console.log(error.text)}
        )
        e.target.reset()
    }

    render () {
        return (
            <div>
                <Header_fr/>
                <br/>
                <br/>

                <div id="form">

                    <h3>QUESTIONS | CONSULTATION</h3>

                    <p>Vous avez une question? N'hésitez pas à nous laisser un message, nous ferons de notre mieux pour
                        vous répondre rapidement. </p>

                    <hr />

                    <form onSubmit={this.sendEmail} enctype="multipart/form-data">
                        <div class="row uniform">
                            <div class="6u 12u$(xsmall)">
                                <input type="text" name="name" id="name" placeholder="Prénom, Nom" />
                            </div>
                            <div class="6u$ 12u$(xsmall)">
                                <input type="email" name="email" id="email" placeholder="Courriel" />
                            </div>
                            <div class="12u$">
                                <div class="select-wrapper">
                                    <select name="category" id="category">
                                        <option value="Unspecified">- Catégorie (optionnel) -</option>
                                        <option value="Business Law">Droit des affaires</option>
                                        <option value="Trademarks">Marques de commerce</option>
                                        <option value="Immigration Law">Droit de l’immigration</option>
                                        <option value="Family Law">Droit de la famille</option>
                                        <option value="Legal Translation">Traduction juridique</option>
                                    </select>
                                </div>
                            </div>

                            <div class="4u$ 12u$(small)">
                                <input type="checkbox" id="priority-high" name="priority" value="high"/>
                                <label for="priority-high">Priorité haute</label>
                            </div>

                            <div class="12u$">
                                <textarea name="message" id="message" placeholder="Écrivez votre message..." rows="6"></textarea>
                            </div>

                            <div class="12u$">
                                <ul class="actions">
                                    <li><input type="submit" value="Send Message" /></li>
                                </ul>
                            </div>
                        </div>
                    </form>

                    <hr />
                </div>

                <footer id="footer">
                    <div class="copyright">
                        <p>Copyright &copy; 2021 <a>Jie Han Consulting</a>. All rights reserved.</p>
                    </div>
                </footer>

            </div>
        )
    }
}