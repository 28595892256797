import React,{Component} from 'react'
import {Link} from 'react-router-dom'

export default class Header_fr extends Component{
    render () {
        return (
            <div>
                <header id="header">
                    <Link className="logo" to={"/main_fr"}><span>Votre  conseillère juridique de confiance</span></Link>
                    <Link to={"/main_en"}>English</Link>
                    <Link to={"/main_fr"}>Français</Link>
                    <Link to={"/main_cn"}>中文</Link>
                </header>

            </div>
        )
    }
}