import React,{Component} from 'react'
import {Link, Route, Switch} from "react-router-dom";
import MainEn from "./index_en";
import MainCn from "./index_cn";
import Header_fr from "./header_fr";

export default class MainFr extends Component{

    componentDidMount() {
        const days = 'sunday,monday,tuesday,wednesday,thursday,friday,saturday'.split(',');
        document.getElementById(days[(new Date()).getDay()]).className = 'bold';
    }
    render () {
        return (
            <div>
                <Header_fr/>
                <section id="banner">
                    <div className="inner">
                        <header>
                            <div className="inline">
                                <img src="images/logo_jiehan.jpg"/>
                                <h1>Jie Han Consultante</h1>
                            </div>
                            <p><em>La résilience est le meilleur atout face à l’adversité.</em></p>
                        </header>
                        <a href="#main" className="button big scrolly">Davantage</a>
                    </div>
                </section>

                <div id="main">
                    <section className="wrapper style1">
                        <div className="inner">
                            <div className="flex flex-2">
                                <div className="col col1">
                                    <div className="image round fit">
                                        <Link to={"/about_fr"} className="link"><img src="images/portrait.jpg"
                                                                                       alt=""/></Link>
                                    </div>
                                </div>
                                <div className="col col2">
                                    <h3>JIE HAN | AVOCATE</h3>
                                    <p>Étant avocate au Barreau du Québec et juriste en Chine, je conseille une<br/>
                                        clientèle locale et internationale avec leurs enjeux en droit des affaires,<br/>
                                        en droit de l’immigration et en droit de la famille.</p>

                                    <Link to={"/about_fr"} className="button">Davantage</Link>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="wrapper style">
                        <div className="inner">
                            <div className="flex flex-2">
                                <div className="col col2">
                                    <h3>SERVICES</h3>
                                    <p>Nous offrons des services dans les domaines de pratique suivants:
                                        <ul>
                                            <li>Droit des affaires</li>
                                            <li>Marques de commerce</li>
                                            <li>Droit de l’immigration</li>
                                            <li>Droit de la famille</li>
                                            <li>Traduction juridique</li>
                                        </ul>
                                    </p>
                                    <Link to={"/service_fr"} className="button">Davantage</Link>
                                </div>
                                <div className="col col1 first">
                                    <div className="image round fit">
                                        <Link to={"/service_fr"} className="link"><img src="images/law2.jpg" alt=""/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="wrapper style1">
                        <div className="inner">
                            <div className="flex flex-2">
                                <div className="col col1">
                                    <div className="image round fit">
                                        <Link to={"/inquiry_fr"} className="link"><img src="images/questionMark.jpg"
                                                                                         alt=""/></Link>
                                    </div>
                                </div>
                                <div className="col col2">
                                    <h3>QUESTIONS | CONSULTATION</h3>
                                    <p>Vous avez une question? N'hésitez pas à nous laisser un message, nous ferons
                                        de notre mieux pour vous répondre rapidement. </p>
                                    <Link to={"/inquiry_fr"} className="button">Davantage</Link>
                                    {/*<a href="inquiry_fr.html" className="button">Davatage</a>*/}
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="wrapper style2">
                        <div className="inner">
                            <header className="align-center">
                                <h2>Coordonnées | Heures d'affaires</h2>
                                <p>Nos clients sont notre priorité, n'hésitez pas à communiquer avec nous durant les heures d'affaires.</p>
                            </header>
                            <div className="flex flex-2">
                                <div className="col align-center">
                                    <table>
                                        <tr className="bold">
                                            <td colSpan="2">COORDONNÉES</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">PORTABLE</td>
                                            <td>(514) 660 - 6611</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">COURRIEL</td>
                                            <td>han.qc@outlook.com</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">WECHAT ID</td>
                                            <td>QuebecLawyer</td>
                                        </tr>
                                    </table>
                                    <div >
                                        <img src="images/wechat.jpg" style={{float:"center"}}
                                             height="130" width="130" alt=""/>
                                    </div>
                                    <p>Numérizer le code QR ci-dessus pour nous ajouter à WeChat</p>
                                </div>
                                <div className="col align-center">
                                    <table>
                                        <tr className="bold">
                                            <td colSpan="2">HEURES D'AFFAIRES</td>
                                        </tr>
                                        <tr id="monday">
                                            <td>Lundi</td>
                                            <td>9:00 - 17:00</td>
                                        </tr>
                                        <tr id="tuesday">
                                            <td>Mardi</td>
                                            <td>9:00 - 17:00</td>
                                        </tr>
                                        <tr id="wednesday">
                                            <td>Mercredi</td>
                                            <td>9:00 - 17:00</td>
                                        </tr>
                                        <tr id="thursday">
                                            <td>Jeudi</td>
                                            <td>9:00 - 17:00</td>
                                        </tr>
                                        <tr id="friday">
                                            <td>Vendredi</td>
                                            <td>9:00 - 17:00</td>
                                        </tr>
                                        <tr id="saturday">
                                            <td>Samedi</td>
                                            <td>Fermé</td>
                                        </tr>
                                        <tr id="sunday">
                                            <td>Dimanche</td>
                                            <td>Fermé</td>
                                        </tr>
                                    </table>
                                </div>


                            </div>
                        </div>
                    </section>

                </div>

                <footer id="footer">
                    <div className="copyright">
                        <p>Copyright &copy; 2021 <a>Jie Han Consulting</a>. All rights reserved.
                        </p>
                    </div>
                </footer>
            </div>
        )
    }
}