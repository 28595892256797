import React,{Component} from 'react'
import Header_cn from "./header_cn";
export default class About_cn extends Component{

    render () {
        return (
            <div id="main">
                <Header_cn/>
                <section className="wrapper">
                    <div className="inner">
                        <header className="align-center">
                            <h1>韩洁 | 律师</h1>
                            <p>非常期待有机会为您的成功助力!</p>
                        </header>
                        <div className="flex flex-1">
                            <div className="col">
                                <p>拥有加拿大法律本科及研究生学历，作为魁省律师协会成员，韩律师同时在中国完成了法律本科的深造。</p>
                                <p>在成为律师以前，她以律师助理身份在商标的注册与管理，各类商业合同的撰写与审查方面累积了丰富的工作经验；<br/>
                                    以实习律师身份独立完成了各类美加签证的咨询与评估，材料撰写与整合，申请递交及进度跟踪。</p>
                                <p>韩律师在创业后一直致力于为中加客户提供优质的个性化的司法服务。在工作之余，韩律师平均每周提供两小时以上<br/>
                                    的公益法律服务，为不同的社会群体提供切合其需求的关爱与帮助。</p>
                            </div>
                        </div>
                    </div>
                </section>

                <footer id="footer">
                    <div className="copyright">
                        <p>Copyright &copy; 2020 <a>Jie Han Consulting</a>. All rights reserved.</p>
                    </div>
                </footer>
            </div>
        )
    }
}